import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Zinsen = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Zinsen – Das Wichtigste im Überblick" showCalc={false} />
            <Article>
                <h2>Welche Arten von Zinsen gibt es?</h2>
                <p>
                    Auf der Liste der Themen, die den meisten Menschen beim Kauf einer Immobilie am meisten
                    Kopfzerbrechen bereiten, stehen die Kreditzinsen ganz oben. Damit machen wir jetzt ein für alle Mal
                    Schluss. Hier erklären wir du kurz und bündig die wichtigsten Zins-Begriffe.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Was genau sind Zinsen eigentlich?</h2>
                <p>
                    Ganz grundlegend kannst du dir unter den Zinsen jenen Preis vorstellen, den du dafür zahlst, dass
                    ein:e Kreditgeber:in dir temporär Kapital zur Verfügung stellt. Man könnte auch sagen, der Zins ist
                    der Preis des Risikos, dass ein:e Kreditgeber:in auf sich nimmt, wenn er dir Geld für dein Vorhaben
                    leiht. Je höher die Wahrscheinlichkeit ist, dass du den Kredit zurückzahlen kannst, desto geringer
                    ist das Risiko für deine:n Kreditgeber:in, was sich wiederum in positiv auf die zu zahlenden Zinsen
                    auswirkt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Unterscheidung nach Zinssatz: Nominal und effektiv</h2>
                <p>
                    Die ersten beiden Begriffe, die du im Zusammenhang mit Zinsen unbedingt verstehen solltest, sind
                    nominaler Zinssatz und effektiver Zinssatz.
                </p>
                <hr />

                <h3>Nominaler Zinssatz</h3>
                <p>
                    Unter dem nominalen Zinssatz versteht man das Entgelt, das du für deinen Kredites bezahlen musst.
                    Man spricht in diesem Zusammenhang auch oft von den Zinskosten. Weitere Kreditnebenkosten werden im
                    nominalen Zinssatz noch nicht berücksichtigt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Effektiver Zinssatz</h3>
                <p>
                    Anders als der nominale enthält der Effektivzins sämtliche Nebenkosten des Kredits. Also neben den
                    Zinskosten auch die Bearbeitungsgebühren, die Kosten für die Eintragung des Pfandrechts in das
                    Grundbuch, Schätzkosten, Kontoführungsgebühren sowie die Prämien der obligatorischen
                    Risikolebensversicherung.
                </p>
                <p>
                    Der effektive Zinssatz ist somit auch der aussagekräftigere Parameter, wenn du dich daran machst,
                    Kreditangebote zu vergleichen. Sofern die Variablen der jeweiligen Offerte (Laufzeit, Zinsbindung
                    etc.) identisch sind. Online verfügbare{" "}
                    <Link to="/dienstleistungen/kreditrechner/" target="_blank" rel="noreferrer noopener">
                        Kreditrechner
                    </Link>{" "}
                    sollte im Idealfall dennoch beide Werte angeben.
                </p>
                <hr />

                <h2>Unterscheidung nach Zinsmodellen: Fixzins vs. variabler Zins</h2>
                <p>Kurzes Intro wie oben.</p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Fixzins</h3>
                <p>
                    Wie der Name schon sagt, bleibt das Zinsniveau bei einem Fixzinskredit über einen vertraglich
                    fixierten Zeitraum unverändert. Diese Kreditvariante erlaubt es dir daher besser zu planen und deine
                    Ausgaben langfristig zu berechnen. Da Sondertilgungen (das sind zusätzliche Rückzahlungen, die über
                    deine vereinbarte Kreditrate hinausgehen) bei Krediten mit diesem Zinsmodell in der Regel mit einer
                    Pönale von 1 % belegt werden, kannst du einen fix verzinsten Kredit mit weniger Eigenkapital
                    finanzieren.
                </p>

                <h3>Variabler Zins</h3>
                <p>
                    Aufgrund des niedrigen Zinsniveaus auf dem Finanzmarkt sind Kredite mit variablen Zinsen zurzeit
                    günstiger als Fixzinskredite. Dennoch solltest du einen Kredit mit diesem Zinsmodell nur dann
                    wählen, wenn du über freie Vermögenswerte, in der Höhe von zumindest einem Drittel des
                    Kreditvolumens verfügst. Das ist ratsam, um in Zukunft Sondertilgungen tätigen zu können, die die
                    Kreditlaufzeit verkürzen. Auch eine möglichst günstige Entwicklung deines Einkommens ist für die
                    Wahl eines Kredits mit variabler Verzinsung essenziell: Ist doch mit zukünftigen Sollzinssätzen von
                    bis zu 6 % zu kalkulieren.
                </p>
                <hr />

                <h2>Woraus ergibt sich die Höhe der Zinsen?</h2>
                <p>
                    Unabhängig vom Zinsmodell, für das du dich entscheidest, ist die konkrete Höhe der Zinsen vor allem
                    von deiner{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    sowie dem Anteil an Eigenmitteln abhängig, den du für die Finanzierung aufwenden kannst. Deine{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    wird von der Bank in einem Ratingprozess ermittelt, in dem folgende Parameter berücksichtigt werden:
                </p>
                <ul>
                    <li>Persönliche Angaben über deine Familiensituation</li>
                    <li>Deine finanzielle Lage</li>
                    <li>Vermögenswerte</li>
                    <li>Eventuelle Verbindlichkeiten</li>
                </ul>
                <p>
                    Je höher deine{" "}
                    <Link to="/artikel/bonitaet/" target="_blank" rel="noreferrer noopener">
                        Bonität
                    </Link>{" "}
                    eingestuft wird, umso wahrscheinlicher ist es nicht nur, dass du die beantragte Kreditsumme
                    erhältst. Auch die Zinsen, die du bei der Rückzahlung zu entrichten hast, werden geringer ausfallen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h3>Kreditzinsen – die aktuelle Situation in Österreich</h3>
                <p>
                    Nicht zuletzt wegen der Corona-Pandemie befinden sich die Zinsen auch hierzulande auf einem beinahe
                    historisch niedrigen Niveau. Erklären lässt sich dies mit der Geldpolitik der Europäischen
                    Zentralbank (EZB), von der auch die Entwicklungen auf dem österreichischen Finanzmarkt abhängig
                    sind. Das in der Eurozone aufgrund der epidemiologischen Einschränkungsmaßnahmen über fünf Quartale
                    hinweg stetig gesunkene BIP hat u.a. zur Folge, dass insbesondere Immobilienkredite derzeit
                    vergleichsweise günstig zu erhalten sind.
                </p>
                <h3>Wie könnten sich die Zinsen in Zukunft entwickeln?</h3>
                <p>
                    Die – ebenfalls aufgrund der Coronakrise – angestiegene Inflation könnte jedoch dazu führen, dass
                    Banken in Zukunft von Kreditinteressent*innen einen höheren Anteil an Eigenmittel bei der
                    Finanzierung einer Immobilie verlangen. Analog dazu könnten zudem die Risikoaufschläge bei
                    Vertragsabschlüssen steigen, was sich langfristig in einer Verteuerung der Konditionen von
                    Neukrediten niederschlagen würde. Bei diesen Entwicklungen handelt es sich jedoch lediglich um
                    mögliche Szenarien, auf die im Augenblick nur äußerst kleinteilige Phänomene auf dem Finanzmarkt
                    schließen lassen. Ausschließen lässt sich das Eintreten dieser Szenarien allerdings nicht und sollte
                    daher bei der Kreditaufnahme möglichst mitbedacht werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Das Wichtigste zum Schluss: Persönliche Beratung</h2>
                <p>
                    So hilfreich online verfügbare tools und Informationen auch sein mögen: Sie bieten dir immer nur
                    eine erste, oft sehr allgemeine Orientierungshilfe. Bevor du also im Dickicht des Zinswissens deine
                    Traumimmobilie aus den Augen verlierst – melde dich bei unseren Finanzierungsexpert*innen und
                    vereinbare einen Termin für ein kostenloses und unverbindliches Beratungsgespräch!
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"zinsen"}></BreadcrumbList>
            <ArticleStructuredData page={"zinsen"} heading={"Zinsen – Das Wichtigste im Überblick"} />
        </Layout>
    );
};

export default Zinsen;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.zinsen", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
